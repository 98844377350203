









import { Vue, Component } from 'vue-property-decorator';
import { BANK_LIST } from '@/common/models/Banks';

@Component({
  name: 'BankView',
})
export default class BankView extends Vue {
  banks = BANK_LIST;

  get bankNames() {
    return this.banks.map((b) => b.label);
  }
}

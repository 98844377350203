export interface LempiBank {
  label: string;
  id: number;
  helpText: string;
  isActive: boolean; // global active
  withdrawActive?: boolean; // withdraw active overrides global active for withdrawals
  depositActive?: boolean; // deposit active overrides global active for deposits
}

export const BANK_LIST: LempiBank[] = [
  {
    label: 'Lempi',
    id: 0,
    helpText: 'Reclama tu cupon aqui!',
    withdrawActive: false,
    isActive: true,
  },
  {
    label: 'BAC',
    id: 1,
    helpText: 'Depositar a cuenta: 743522821',
    isActive: true,
  },
  {
    label: 'Ficohsa',
    id: 2,
    helpText: 'Depositar a cuenta: 200003250538',
    isActive: true,
  },
  {
    label: 'Atlantida',
    id: 3,
    helpText: 'Depositar a cuenta: 13320205431',
    isActive: true,
  },
  {
    label: 'Davivienda',
    id: 4,
    helpText: 'Depositar a cuenta: 1201436123',
    isActive: true,
  },
  {
    label: 'Promerica',
    id: 5,
    helpText: 'Proximamente',
    isActive: false,
  },
  {
    label: 'Banpaís',
    id: 6,
    helpText: 'Proximamente',
    isActive: false,
  },
  {
    label: 'Banco de Occidente',
    id: 7,
    helpText: 'Proximamente',
    isActive: false,
  },
  {
    label: 'Tigo Money',
    id: 8,
    helpText: 'Depositar a número: 97703148',
    isActive: true,
  },
  {
    label: 'Ficensa',
    id: 9,
    helpText: 'Proximamente',
    isActive: false,
  },
  {
    label: 'Lafise',
    id: 10,
    helpText: 'Proximamente',
    isActive: false,
  },
  {
    label: 'Tengo App',
    id: 11,
    helpText: 'Depositar a número: 97703148',
    isActive: true,
  },
];

export interface RawBank {
  name: string;
  type: string;
  account: string;
}
